@use "variables" as *;

html {
	color-scheme: light !important;
	background-color: $body-bg !important;
	min-height: 100vh;
	font-family: $font-family-base;
}

:root {
	color-scheme: only light;
}

body {
	background-color: $body-bg;
	min-height: 100vh;
}

main {
	min-height: 100vh;

	.container {
		width: 100%;
		max-width: 132rem;
		margin: 0 auto;
		min-height: 100vh;

		display: flex;
		flex-direction: column;
	}
}