// ----------------------------
//  Section
// ----------------------------

@use "variables" as *;
@use "@coreui/coreui/scss/mixins/breakpoints" as *;

h1, .h1 {
	font-size: clamp(5rem, 10vw, 13rem);
	line-height: 0.66;
	letter-spacing: -7px;
	font-weight: $font-weight-light;
	margin-bottom: 0;
	padding-top: 0;

	@include media-breakpoint-down(lg) {
		letter-spacing: -5px;
	}

	@include media-breakpoint-down(md) {
		letter-spacing: -2px;
	}
}

h2, .h2 {
	font-size: clamp(2.6rem, 5vw, 5.2rem);
	letter-spacing: -0.1rem;
	font-weight: $font-weight-bold;
	margin-bottom: 0;
	padding-top: 0;
	line-height: 1.1;
}


h1:first-child, .h1:first-child,
h2:first-child, .h2:first-child {
	margin-top: 0;
}

p, li {
	font-size: clamp(1.8rem, 3vw, 3.2rem);
	line-height: 1.4;
	letter-spacing: -1px;

	strong  {
		font-weight: $font-weight-bold;
	}

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: underline dotted;
			text-decoration-thickness: 0.1rem;
		}
	}
}