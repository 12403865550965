/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

@use "variables" as *;
@use "@coreui/coreui/scss/mixins/breakpoints" as *;

header {
	height: 30.3rem;
	background: url(../images/header_logo.svg) 3rem top no-repeat;
	background-size: auto 27.3rem;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: flex-end;
	padding: 3rem;
	padding-top: 0;

	@include media-breakpoint-down(lg) {
		height: 25rem;
		background-position: 3rem calc(100% - 3rem);
		background-size: auto 22rem;
	}

	@include media-breakpoint-down(md) {
		height: 20rem;
		background-size: auto 17rem;
	}

	@include media-breakpoint-down(sm) {
		height: 15rem;
		background-size: auto 12rem;
	}
}