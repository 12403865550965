/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

@use "variables" as *;
@use "@coreui/coreui/scss/mixins/breakpoints" as *;

footer {
	padding: 3rem;
	height: 26rem;
	background: url(../images/footer_logo.svg) calc(100% - 3rem) calc(100% - 3rem) no-repeat;

	@include media-breakpoint-down(lg) {
		height: 20rem;
		background-size: 10rem
	}

	@include media-breakpoint-down(md) {
		height: 15rem;;
	}
}