/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

@use "variables" as *;
@use "@coreui/coreui/scss/mixins/breakpoints" as *;

.section {
	padding: 8rem 3rem 3rem 3rem;
	flex: 1 1 auto;

	&__head {
		margin-bottom: 4.2rem;
	}

	&__content {
		margin-bottom: 4.2rem;

		p {
			margin-bottom: 2.6rem;
		}
	}

	&__footnote {
		h2 {
			color: $white;
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 6rem;

		&__head, &__content {
			margin-bottom: 4rem;
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 3rem;

		&__head, &__content {
			margin-bottom: 3rem;
		}

		&__content p {
			margin-bottom: 2rem;
			letter-spacing: -0.02em;
		}
	}
}